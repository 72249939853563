
//@ts-nocheck
import Vue from "vue";
import { Advertiser, Category } from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength,
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import CardActions from "@/components/Content/CardAction.vue";
import { isEmpty, isNull } from "lodash";
//@ts-check

const ACCOUNT = "rappi_amplify";
const MAKERS_NAMES_ENABLED = ["Otros", "Otro"];

export default Vue.extend({
	name: "AdvertiserCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardTextFieldToolTip,
		CardActions,
	},
	data: () => ({
		title: "Create",
		advertiser: {
			name: "",
			category_id: "",
			app_bundle: "",
			domain: "",
			active: true,
			maker: null,
			country_id: null,
			trademark_id: null,
		},
		valid: true,
		loading: false,
		show_tooltip_app_bundle: false,
		show_tooltip_domain: false,
		countries: [],
		makers: [],
		tradeMarks: [],
	}),
	created() {},
	async mounted() {
		try {
			this.loading = true;
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchCategories();
			await this.setLoadingData();
			if (this.isAccountRappi) {
				await this.loadData();
			}
			this.loading = false;
			this.$refs.form.resetValidation();
		} catch (error) {
			await this.setLoadingData();
			this.loading = false;
		}
	},
	computed: {
		...mapGetters("profile", ["account"]),
		getCategories(): Category[] {
			return this.$store.state.advertiser.categories;
		},
		getCountrys() {
			return this.countries;
		},
		getMarkers() {
			return this.makers;
		},
		getTradeMarks() {
			return this.tradeMarks;
		},
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength,
			};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		isAccountRappi() {
			return this.account.account_type === ACCOUNT;
		},
		isDisabledName() {
			return (
				this.advertiser.maker !== null &&
				!MAKERS_NAMES_ENABLED.includes(this.advertiser.maker.value)
			);
		},
		disabledMakers() {
			return isNull(this.advertiser.country_id);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", ["fetchData"]),
		...mapActions("advertiser", ["makersList", "tradeMarksList"]),
		...mapActions("proccess", ["setLoadingField"]),

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{ root: true }
			);
		},

		async loadData() {
			await this.fetchCountries();
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.create();
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		handleCancel() {
			this.$router.push({ name: "AdvertisersIndex" });
		},
		toggleTooltipAppBundle() {
			this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
		},
		toggleTooltipDomain() {
			this.show_tooltip_domain = !this.show_tooltip_domain;
		},
		toggleStatus(status: boolean) {
			this.advertiser.active = Boolean(status);
		},
		async dispatchCategories() {
			return this.$store.dispatch("advertiser/getCategories", {
				root: true,
			});
		},

		async fetchMakers() {
			if (!this.advertiser.country_id) {
				return;
			}
			this.setLoadingField(true);
			const payload = { country_id: this.advertiser.country_id };
			await this.makersList({ filters: payload })
				.then(async (resp) => {
					await this.parseDataMakers(resp);
					this.setLoadingField(false);
				})
				.catch(async (err) => {
					this.makers = [];
					this.setLoadingField(false);
				});
		},
		async parseDataMakers(makers) {
			this.makers = makers.map((maker) => {
				return { id: maker.id, value: maker.name };
			});
		},

		async fetchTradeMarks() {
			if (this.advertiser.maker === null) {
				return;
			}
			this.setLoadingField(true);
			const payload = {
				country_id: this.advertiser.country_id,
				maker_id: this.advertiser.maker.id,
			};
			await this.tradeMarksList({ filters: payload })
				.then(async (resp) => {
					await this.parseDataTradeMarks(resp);
					this.setLoadingField(false);
				})
				.catch(async (err) => {
					this.tradeMarks = [];
					this.setLoadingField(false);
				});
		},

		async parseDataTradeMarks(tradeMarks) {
			this.tradeMarks = tradeMarks.map((tradeMark) => {
				return { id: tradeMark.id, value: tradeMark.name };
			});
		},

		async fetchCountries() {
			let params = {
				type: "countries",
			};
			this.setLoadingField(true);
			await this.fetchData(params)
				.then(async (resp) => {
					await this.parseDataCountry(resp);
					this.setLoadingField(false);
				})
				.catch(async (err) => {
					this.setLoadingField(false);
				});
		},
		async parseDataCountry(countries: any) {
			countries.forEach((c) => {
				let { title, items } = c;
				this.countries.push({ header: title });
				items.forEach((i) => {
					let { id, value } = i;
					this.countries.push({ group: title, id: id, value: value });
				});
			});
		},

		async create() {
			const payload = {
				...this.advertiser,
				maker_id: this.advertiser?.maker?.id,
				maker: undefined,
			};
			return this.$store.dispatch("advertiser/create", payload, {
				root: true,
			});
		},
		async setData(value: string) {
			this.advertiser.name = value;
		},
	},
	watch: {
		async "advertiser.maker.value"(val, old) {
			if (this.loading) return;
			this.advertiser.name = val;
			this.tradeMarks = [];
			await this.setData(val);
		},
		async "advertiser.country_id"(val, old) {
			if (this.loading) return;
			this.makers = [];
			this.advertiser.maker = null;
			this.advertiser.name = "";
		},
	},
});
